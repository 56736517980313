import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    Tabs,
    Tab

} from "react-bootstrap";

import { Edit, Users, ChevronUp, ChevronDown } from "react-feather";

import cloneDeep from "lodash/cloneDeep";

import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import PillButtons from "../../Components/PillButtons";
// import Scrollers from "../../Components/Scrollers/Scrollers";


import "./Styles.css";

const assignGroups = [
    { "groupId": 303, "groupName": "Admin", "allowedGroupIds": [303, 1212] },
    { "groupId": 202, "groupName": "Co ROSnet", "allowedGroupIds": [] },
    { "groupId": 404, "groupName": "DAO", "allowedGroupIds": [505, 606, 909, 1111, 1212] },
    { "groupId": 505, "groupName": "DM", "allowedGroupIds": [] },
    { "groupId": 606, "groupName": "GM", "allowedGroupIds": [404, 505, 606, 707, 909, 1010, 1111, 1212] },
    { "groupId": 707, "groupName": "HR Business Partner", "allowedGroupIds": [] },
    { "groupId": 808, "groupName": "Individual Access", "allowedGroupIds": [1111] },
    { "groupId": 909, "groupName": "Operations Executive", "allowedGroupIds": [303, 1212] },
    { "groupId": 1010, "groupName": "Operations Finance", "allowedGroupIds": [] },
    { "groupId": 1111, "groupName": "Payroll", "allowedGroupIds": [] },
    { "groupId": 1212, "groupName": "Restaurant", "allowedGroupIds": [] }
]

const shareGroups = [
    { "groupId": 303, "groupName": "Admin", "allowedGroupIds": [1212] },
    { "groupId": 202, "groupName": "Co ROSnet", "allowedGroupIds": [] },
    { "groupId": 404, "groupName": "DAO", "allowedGroupIds": [] },
    { "groupId": 505, "groupName": "DM", "allowedGroupIds": [] },
    { "groupId": 606, "groupName": "GM", "allowedGroupIds": [303] },
    { "groupId": 707, "groupName": "HR Business Partner", "allowedGroupIds": [] },
    { "groupId": 808, "groupName": "Individual Access", "allowedGroupIds": [] },
    { "groupId": 909, "groupName": "Operations Executive", "allowedGroupIds": [] },
    { "groupId": 1010, "groupName": "Operations Finance", "allowedGroupIds": [] },
    { "groupId": 1111, "groupName": "Payroll", "allowedGroupIds": [] },
    { "groupId": 1212, "groupName": "Restaurant", "allowedGroupIds": [1212] }
]


function SectionList() {

    const [assignGroupList, setAssignGroupList] = useState(assignGroups)
    const [shareGroupList, setShareGroupList] = useState(shareGroups)
    const [showModal, setShowModal] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedPermission, setSelectedPermission] = useState('assign')
    const [groupSelections, setGroupSelections] = useState([])

    function createList(permission) {

        let list = []
        if (permission === 'assign') {
            assignGroupList.forEach(function (group, index) {

                // add a new allowedGroups array with id and name
                let copyGroup = { ...group, allowedGroups: [] }
                copyGroup.allowedGroupIds.forEach(function (allowedGroupId, allowedIndex) {
                    const found = assignGroupList.find((s) => s.groupId === allowedGroupId);
                    if (found) {
                        copyGroup.allowedGroups.push({ groupId: found.groupId, groupName: found.groupName })
                    }
                })

                list.push(copyGroup)
            })
        }
        else {
            shareGroupList.forEach(function (group, index) {

                // add a new allowedGroups array with id and name
                let copyGroup = { ...group, allowedGroups: [] }
                copyGroup.allowedGroupIds.forEach(function (allowedGroupId, allowedIndex) {
                    const found = assignGroupList.find((s) => s.groupId === allowedGroupId);
                    if (found) {
                        copyGroup.allowedGroups.push({ groupId: found.groupId, groupName: found.groupName })
                    }
                })

                list.push(copyGroup)
            })
        }


        return list;
    }

    function buildPermissionList(permission) {

        let list = createList(permission)

        return (
            <div>

                <div className="d-flex flex-column gap-3">
                    {list.map((group, index) => (

                        <section key={index} className={group.groupId === selectedGroup?.groupId ? "hover-effect selected" : "hover-effect"}>

                            <div className="d-flex flex-row align-text-top gap-4">
                                <div className="avatar">
                                    <div className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: 50, height: 50, backgroundColor: '#004B87' }}>
                                        <Users color={'white'} />
                                    </div>
                                </div>
                                <div className="flex-grow-1 title-container">
                                    <h5 className="no-select">{group.groupName}</h5>
                                    <div className="description mb-3 no-select">
                                        {group.allowedGroupIds.length === 0 &&
                                            <>Edit the {group.groupName} group to select who it is allowed to {permission === 'assign' ? 'assign dashboards to' : 'share dashboards with'}.</>
                                        }
                                        {group.allowedGroupIds.length > 0 &&
                                            <>The {group.groupName} group is allowed to {permission === 'assign' ? 'assign dashboards to' : 'share dashboards with'} these groups.</>
                                        }
                                    </div>

                                    {group.allowedGroupIds.length > 0 &&
                                        <PillButtons
                                            // if you want to pre-select one of the options in your list
                                            defaultOption={null}
                                            allowMultiple={false}
                                            allowHover={false}
                                            options={group.allowedGroups.map(a => ({
                                                label: a.groupName,
                                                value: a.groupId
                                            }))}
                                            overflowHidden={true}
                                            onSelectOption={(list) => console.log("list", list)}
                                        />
                                    }
                                </div>

                                <div className="flex-grow-0 align-top">
                                    <Button variant="link" className="btn-no-link" onClick={() => handleEditClick(group, permission)}>
                                        <Edit size={20} strokeWidth={1} color={'#333f48'} />
                                    </Button>
                                </div>
                            </div>
                        </section>

                    ))}
                </div>



            </div>
        )

    }


    function handleEditClick(group, permission) {

        let list = createList(permission)

        let copyGroup = { ...group, allowedGroups: [] }
        copyGroup.allowedGroupIds.forEach(function (allowedGroupId, allowedIndex) {
            const found = list.find((s) => s.groupId === allowedGroupId);
            if (found) {
                copyGroup.allowedGroups.push({ groupId: found.groupId, groupName: found.groupName })
            }
        })

        setSelectedPermission(permission)
        setShowModal(true)
        setSelectedGroup(copyGroup)
    }

    function onSaveChanges(save) {
        setShowModal(false)

        if (save) {
            setTimeout(() => {

                let copyList = cloneDeep(assignGroupList)
                if (selectedPermission === 'share') {
                    copyList = cloneDeep(shareGroupList)
                }

                const found = copyList.find((s) => s.groupId === selectedGroup.groupId);
                if (found) {
                    found.allowedGroupIds = groupSelections.map(a => a.value)
                }

                if (selectedPermission === 'share') {
                    setShareGroupList(copyList)
                }
                else {
                    setAssignGroupList(copyList)
                }

            }, 200)
        }

        setTimeout(() => {
            setSelectedGroup(null)
        }, 500)

    }

    return (
        <Container fluid className="assign-share min-vh-100  allow-vertical-scroll">

            <TopNav dataTransferSuccess={true} />

            <Row className="justify-content-md-center mb-3">
                <Col sm="12" md="12" lg="6" xl="6" xxl="6">

                    <section className="mt-3">

                        <h4>Permissions to Assign/Share Dashboards</h4>

                        <p className="mt-3 mb-4">Control group-level permissions for assigning and sharing dashboards.</p>


                        <Tabs defaultActiveKey="assigning">
                            <Tab
                                eventKey="assigning"
                                title="Permission to Assign"
                            >
                                <section className="gray p-3 mt-2 mb-3">
                                    {buildPermissionList('assign')}
                                </section>
                            </Tab>

                            <Tab
                                eventKey="sharing"
                                title="Permission to Share"
                            >
                                <section className="gray p-3 mt-2 mb-3">
                                    {buildPermissionList('share')}
                                </section>

                            </Tab>
                        </Tabs>
                    </section>

                </Col>
            </Row>

            {/* Up and down scrollers instead of a scrollbar */}
            {/* <Scrollers /> */}


            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedGroup?.groupName} Permissions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <p>Select one or more groups that the {selectedGroup?.groupName} group can {selectedPermission === 'assign' ? 'assign dashboards to' : 'share dashboards with'}.</p>

                    <div className="mb-3">
                        <PillButtons
                            options={createList(selectedPermission).map(a => ({
                                label: a.groupName,
                                value: a.groupId
                            }))}
                            // if you want to pre-select one or more of the options in your list
                            defaultOptions={selectedGroup?.allowedGroups.map(a => ({
                                label: a.groupName,
                                value: a.groupId
                            }))}
                            allowMultiple={true}
                            overflowHidden={true}
                            onSelectOption={(list) => setGroupSelections(list)}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => onSaveChanges(true)}>
                        Save Changes
                    </Button>
                    <Button variant="light" onClick={() => onSaveChanges(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export default SectionList;