import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Form,
    ProgressBar,
    Modal,
    Table,
    Offcanvas,
    Tabs,
    Tab
} from "react-bootstrap";

import { ArrowLeftCircle } from "react-feather";

import cloneDeep from "lodash/cloneDeep";

import TopNav from "../../../Components/TopNavMegaMenu/TopNavMegaMenu";
import FieldList from "../../../Components/PayrollExport/FieldList";
import CaseBuilderModal from "../../../Components/PayrollExport/CaseBuilder/CaseBuilderModal";

import "./Styles.css";
import "../../../Styles/OffcanvasShared.css";

const defaultFieldList = [
    { id: 1, title: 'pos_emp_name', customTitle: 'Employee Name', sampleData: ["Larry", "BOB", "george", "blank", "biLL"] },
    { id: 2, title: 'bus_date', customTitle: '', sampleData: ["2024-06-01", "6/1/2024", "06/01/2024", "blank", "NULL"] },
    { id: 3, title: 'base_rate', customTitle: '', sampleData: ["11.50", "null", "15.25", "0", "-2.25"] },
    { id: 4, title: 'hrs_worked', customTitle: '', sampleData: ["null", "NULL", "30", "25000", "-400"] },
    { id: 5, title: 'location', customTitle: '', sampleData: ["Wood River", "NULL", "blank", "Arlington", "springfield"] },
]

const defaultColumnList = ["pos_emp_name", "bus_date", "base_rate", "hrs_worked", "location"]
const defaultDatasetList = [{ label: "Sales Data", value: "payroll-export" }, { label: "Labor Data", value: "employee-export" }]
const defaultViewList = ["Daily Trends", "Top Selling Items", "Sales by Category"]

const sampleList = [
    {
        "id": 1736518202325,
        "title": "pos_emp_name",
        "customTitle": "",
        "sampleData": [
          "11.50",
          "null",
          "15.25",
          "0",
          "-2.25"
        ]
      },
      {
        "id": 1736518246883,
        "title": "bus_date",
        "customTitle": "",
        "sampleData": [
          "11.50",
          "null",
          "15.25",
          "0",
          "-2.25"
        ]
      },
      {
        "id": 1736518263850,
        "title": "base_rate",
        "customTitle": "",
        "sampleData": [
          "11.50",
          "null",
          "15.25",
          "0",
          "-2.25"
        ]
      }
]

function SelfServiceReport() {

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({ actionLabel: '', fieldLabel: '', sampleData: [] });
    const [datasetList, setDatasetList] = useState(defaultDatasetList);
    const [viewList, setViewList] = useState(defaultViewList);
    const [columnList, setColumnList] = useState(defaultColumnList);
    const [fieldList, setFieldList] = useState(sampleList);
    const [selectedDataset, setSelectedDataset] = useState(defaultDatasetList[0]);
    const [selectedView, setSelectedView] = useState(defaultViewList[0]);
    const [selectedColumn, setSelectedColumn] = useState(defaultColumnList[0]);
    const [showWarning, setShowWarning] = useState(false);
    const [showWhatsInView, setShowWhatsInView] = useState(false);
    const [showBuilder, setShowBuilder] = useState(false);
    const [selectedCustomColumn, setSelectedCustomColumn] = useState(null);
    const [showSaveModal, setShowSaveModal] = useState(false);

    useEffect(() => {

        document.title = 'Self-Service Reporting | PowerCenter';
    }, []);


    function onMoreClicked(actionLabel, field) {

        console.log(actionLabel, field.title);
        setShowModal(true);
        setModalData({ actionLabel, fieldLabel: field.title, sampleData: field.sampleData })
    }

    function onDeleteClicked(field) {
        const newList = fieldList.filter((s) => s.id !== field.id);
        setFieldList(newList)
    }

    // this can be used to determine what fields in the list are currently selected/checked
    function onFieldsChanged(list) {
        //console.log("fields changed", JSON.stringify(list, null, 2))
        setFieldList(list)
    }

    function onAddSelectedColumn() {

        const fakeid = new Date().getTime()

        let copyList = cloneDeep(fieldList)
        const newItem = { id: fakeid, title: selectedColumn, customTitle: '', sampleData: ["11.50", "null", "15.25", "0", "-2.25"] }
        console.log("new", JSON.stringify(newItem, null, 2))
        copyList.push(newItem)
        setFieldList(copyList)

        // mostly needed for first item in the list - create a little delay to allow the list to be re-rendered before scrolling
        setTimeout(() => {
            window.scrollBy({
                top: 200,
                behavior: "smooth",
            });
        }, copyList.length === 1 ? 100 : 50)


    }

    function onViewChange(val) {

        // only show warning if columns have been selected
        if (fieldList.length > 0) {
            setShowWarning(true)
        }
        setSelectedView(val)

    }

    function onApplyCustomColumn(data) {

        console.log("onApply", JSON.stringify(data, null, 2))

        const fakeid = new Date().getTime()

        let copyList = cloneDeep(fieldList)
        copyList.push({ id: fakeid, title: 'custom', customTitle: '', sampleData: ["11.50", "null", "15.25", "0", "-2.25"], customProps: data.conditionGroups, customDefaultReturnValue: data.defaultReturnValue })
        setFieldList(copyList)

        // mostly needed for first item in the list - create a little delay to allow the list to be re-rendered before scrolling
        setTimeout(() => {
            window.scrollBy({
                top: 200,
                behavior: "smooth",
            });
        }, copyList.length === 1 ? 100 : 50)
    }

    function onUpdateCustomColumn(data) {

        console.log("onUpdate", JSON.stringify(data, null, 2))

        let copyList = JSON.parse(JSON.stringify(fieldList))

        console.log("copyList", copyList)


        let found = copyList.find(f => f.id === selectedCustomColumn.id);
        if (found) {
            found.customProps = data.conditionGroups
            found.customDefaultReturnValue = data.defaultReturnValue
        }

        setFieldList(copyList)


    }

    function onEditCustomClicked(field) {

        console.log("edit custom", field)

        setShowBuilder(true)

        setSelectedCustomColumn(field)

    }

    return (
        <Container fluid className="export">

            <TopNav dataTransferSuccess={true} />

            <Row className="justify-content-md-center mb-5 pt-3">

                <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                    {/* <div className="mb-3">
                        <Button variant="link" className="btn-link-off" onClick={() => console.log("back")}><ArrowLeftCircle size={20} color={'gray'} style={{ marginRight: 5 }} />Back to list</Button>
                    </div> */}

                    <section className="mb-3">
                        <h4>Self-Service Reporting</h4>
                        <p className="mb-0">Create a customized report that you can export and save for later use.</p>
                    </section>


                    <section className="mb-3">

                        <h4>Select Columns for Your Report</h4>

                        <p>Select a Dataset and View that contains the columns that you would like to include in your report.</p>

                        <form className="mb-3">
                            <div className="mb-3">
                                <label htmlFor="dataset" className="form-label">Dataset</label>
                                <select className="form-select" id="dataset" aria-label="Dataset Selection" style={{ width: 'auto' }} onChange={(s) => setSelectedDataset(s)}>
                                    {datasetList && datasetList?.map((item, index) => (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>


                            <div>
                                <label htmlFor="view" className="form-label">View</label>
                                <div className="d-flex flex-row flex-grow-0 p-0 gap-2">
                                    <select className="form-select" id="view" aria-label="View Selection" style={{ width: 'auto' }} onChange={(e) => onViewChange(e.target.value)}>
                                        {viewList && viewList?.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>

                                    <Button variant="link" className="btn-link-off" onClick={() => setShowWhatsInView(true)}>What's in this View?</Button>
                                </div>
                            </div>
                        </form>

                        {fieldList.length > 0 &&
                            <section className="gray p-3 mb-3">
                                <h6><strong>Selected Columns</strong></h6>

                                <p>Give your columns custom heading labels, apply custom formatting, and use drag-and-drop to rearrange them.</p>
                                <FieldList items={fieldList} onFieldsChanged={onFieldsChanged} onMoreClicked={onMoreClicked} onDeleteClicked={onDeleteClicked} onEditCustomClicked={onEditCustomClicked} />
                            </section>
                        }


                        <section className="gray">

                            <h6><strong>Available Columns</strong></h6>
                            <p>Select columns that are available in the {selectedView} view.</p>

                            <div className="d-flex flex-row flex-grow-0 p-0 gap-2">

                                <select className="form-select" id="available-columns" aria-label="Dataset Selection" style={{ width: 'auto' }} onChange={(e) => setSelectedColumn(e.target.value)}>

                                    {columnList && columnList?.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>


                                <Button variant="primary" onClick={() => onAddSelectedColumn()}>Add Selected Column</Button>
                                <Button variant="link" className="btn-link-off" onClick={() => {
                                    setShowBuilder(true)
                                    setSelectedCustomColumn(null)
                                }}>Create a Custom Column</Button>
                            </div>

                        </section>



                    </section>



                    <section className="mt-3">

                        <h4>Filtering Options</h4>

                        <p>Select filtering options that are available for your selected dataset and view.</p>

                        <p>(this would resemble the filtering options we use for dashboard and widget filtering)</p>

                    </section>


                    <section className="mt-3 mb-3">

                        <h4>Run Your Report</h4>

                        <p>Once you have previewed your report and are happy with the results, you can save it and run it again later.</p>

                        <div className="d-flex flex-row flex-grow-0 p-0 gap-2">
                            <Button variant="primary">Run Report</Button>
                            <Button variant="link" className="btn-link-off" onClick={() => setShowSaveModal(true)}>Save this report?</Button>
                            {/* <Button variant="outline-success" disabled>Publish</Button> */}
                        </div>

                    </section>



                </Col>

            </Row>


            <Offcanvas
                show={showModal}
                onHide={() => setShowModal(false)}
                placement="end"
                className="oc-shared width-25"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title>{modalData?.fieldLabel}</Offcanvas.Title>
                    {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
                    <button
                        type="button"
                        className="btn-close btn-close-white"
                        aria-label="Close"
                        onClick={() => setShowModal(false)}
                    ></button>
                </Offcanvas.Header>
                <Offcanvas.Body className="help p-3">


                    <p><strong>Apply Formatting</strong></p>

                    <Form.Check
                        type="switch"
                        id="format-money"
                        label="Money/Currency"
                        className="mt-3 mb-3"
                    />

                    <Form.Check
                        type="switch"
                        id="format-date"
                        label="Date/Time"
                        className="mt-3 mb-3"
                    />

                    <Form.Check
                        type="switch"
                        id="format-uppercase"
                        label="Upper Case"
                        className="mt-3 mb-3"
                    />

                    <p className="mt-4"><strong>Sample Data</strong></p>
                    <Table hover bordered responsive>
                        <tbody>
                            {modalData?.sampleData?.map((item, index) => (
                                <tr>
                                    <td>{item}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </Offcanvas.Body>
            </Offcanvas>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showSaveModal} onHide={() => setShowSaveModal(!showSaveModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Your Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>To save your report, enter a name and optional description.</p>
    
                    <form className="mt-4">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Report Name</label>
                                <input type="text" className="form-control" id="name" placeholder="" value="My Sales Report" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="desc" className="form-label">Report Description</label>
                                <textarea className="form-control" id="desc" rows="4" placeholder="" value="This report shows my sales data for the past quarter." />
                            </div>

                        </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowSaveModal(!showSaveModal)}>
                        Save
                    </Button>
                    <Button variant="light" onClick={() => setShowSaveModal(!showSaveModal)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showWarning} onHide={() => setShowWarning(!showWarning)}>
                <Modal.Header closeButton>
                    <Modal.Title>Please Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to change your view? Some columns you have previously selected may not be available in this view.</p>
                    <p style={{ fontSize: 12, fontStyle: 'italic' }}>DEV NOTE: This should only appear if the user has columns currently selected that are not found in the newly-selected view.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowWarning(!showWarning)}>
                        Continue
                    </Button>
                    <Button variant="light" onClick={() => setShowWarning(!showWarning)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                dialogClassName="modal-90w"
                show={showWhatsInView} onHide={() => setShowWhatsInView(!showWhatsInView)}>
                <Modal.Header closeButton>
                    <Modal.Title>What's in this View?</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>The '{selectedView}' view contains the following columns.</p>

                    <Tabs defaultActiveKey="table">
                        <Tab
                            eventKey="table"
                            title="Sample Data"
                            className="text-primary"
                        >

                            <Table responsive>
                                <thead>
                                    <tr>
                                        {Array.from({ length: 12 }).map((_, index) => (
                                            <th key={index}>Heading {index}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {Array.from({ length: 12 }).map((_, index) => (
                                            <td key={index}>Cell {index}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        {Array.from({ length: 12 }).map((_, index) => (
                                            <td key={index}>Cell {index}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        {Array.from({ length: 12 }).map((_, index) => (
                                            <td key={index}>Cell {index}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </Table>

                        </Tab>

                        <Tab
                            eventKey="details"
                            title="Column Details"
                        >

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Column Name</th>
                                        <th scope="col">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>pos_emp_name</td>
                                        <td>Description goes here</td>
                                    </tr>
                                    <tr>
                                        <td>base_rate</td>
                                        <td>Description goes here</td>
                                    </tr>
                                    <tr>
                                        <td>bus_date</td>
                                        <td>Description goes here</td>
                                    </tr>
                                    <tr>
                                        <td>hrs_worked</td>
                                        <td>Description goes here</td>
                                    </tr>
                                    <tr>
                                        <td>location</td>
                                        <td>Description goes here</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tab>
                    </Tabs>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowWhatsInView(!showWhatsInView)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {showBuilder &&
                <CaseBuilderModal show={showBuilder}
                    selectedGroup={selectedCustomColumn}
                    onHide={() => setShowBuilder(false)}
                    onApply={(data) => onApplyCustomColumn(data)}
                    onUpdate={(data) => onUpdateCustomColumn(data)}
                />
            }


        </Container>
    );
}

export default SelfServiceReport;