import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { Clock, MapPin, Info, Calendar, AlertCircle } from "react-feather";
import { BeatLoader } from "react-spinners";
import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import IconAlert from "../../Components/Icons/Alert";
import dayjs from "dayjs";
import "./Styles.css"

function Maintenance() {
    const [maintMode, setMaintMode] = useState('warning');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document.title = 'Planned Maintenance in Progress | PowerCenter';

        // Get a specific parameter value
        const urlParams = new URLSearchParams(window.location.search);
        let maintValue = urlParams.get('mode') || 'warning';
        setMaintMode(maintValue)

        setTimeout(() => {
            setIsLoading(false)
        }, 1000)

    }, []);


    return (
        <Container fluid className="maint min-vh-100">

            {/* <TopNav dataTransferSuccess={true} maintenanceMode={maintMode === 'active' ? true : false} /> */}
            {/* Hiding top nav items for warning and active maintenance messaging */}
            <TopNav dataTransferSuccess={true} maintenanceMode={false} />

            <Row className="justify-content-md-center mb-3">
                <Col sm="12" md="12" lg="6" xl="6" xxl="4">

                    <section className="mt-3">
                        {maintMode === "warning" &&
                            <>
                                <div className="list-item no-animation mb-4">
                                    <div className="item-container">
                                        <div className="avatar">
                                            <IconAlert color={maintMode === 'warning' ? '#FFB81C' : '#E03C31'} />
                                        </div>
                                        <div className="title-container">
                                            <div className="title"><h3>Planned System Maintenance</h3></div>
                                            <div className="description">
                                                PowerCenter will be offline for scheduled maintenance on the following dates:
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                {isLoading && (
                                    <div className="loading-box">
                                        <BeatLoader color={"#004b87"} /><span className="ms-3">Gathering a list of affected clients...</span>
                                    </div>
                                )}

                                {!isLoading &&
                                    <>
                                        <div className="calendar-list-item animate-flip-vertical">
                                            <div className="date">
                                                <div className="day-name">{dayjs(new Date()).add(2, 'day').format("ddd").toUpperCase()}</div>
                                                <div className="day-nbr">{dayjs(new Date()).add(2, 'day').format("DD")}</div>
                                            </div>
                                            <div className="d-flex align-items-center p-1">
                                                <div className="vertical-line"></div>
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center p-3">
                                                <h5>AAG - Flynn Applebee's</h5>
                                                <div className="mb-2"><Calendar size="15" /> {dayjs(new Date()).add(2, 'day').format("dddd, MMMM D")}</div>
                                                <div><Clock size="15" /> 10pm CST</div>
                                            </div>
                                            <div className="date">
                                                <div className="day-nbr small">2</div>
                                                <div className="day-name small">DAYS</div>
                                            </div>
                                        </div>

                                        <div className="calendar-list-item animate-flip-vertical mt-2">
                                            <div className="date">
                                                <div className="day-name">{dayjs(new Date()).add(2, 'day').format("ddd").toUpperCase()}</div>
                                                <div className="day-nbr">{dayjs(new Date()).add(2, 'day').format("DD")}</div>
                                            </div>
                                            <div className="d-flex align-items-center p-1">
                                                <div className="vertical-line"></div>
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center p-3">
                                                <h5>CACTUS - Cactus Club Cafe</h5>
                                                <div className="mb-2"><Calendar size="15" /> {dayjs(new Date()).add(2, 'day').format("dddd, MMMM D")}</div>
                                                <div><Clock size="15" color="gray" /> 10pm CST</div>
                                            </div>
                                            <div className="date">
                                                <div className="day-nbr small">2</div>
                                                <div className="day-name small">DAYS</div>
                                            </div>
                                        </div>

                                        <div className="calendar-list-item animate-flip-vertical mt-2">
                                            <div className="date">
                                                <div className="day-name">{dayjs(new Date()).add(3, 'day').format("ddd").toUpperCase()}</div>
                                                <div className="day-nbr">{dayjs(new Date()).add(3, 'day').format("DD")}</div>
                                            </div>
                                            <div className="d-flex align-items-center p-1">
                                                <div className="vertical-line"></div>
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center p-3">
                                                <h5>DChevys - Doherty Chevys</h5>
                                                <div className="mb-2"><Calendar size="15" /> {dayjs(new Date()).add(3, 'day').format("dddd, MMMM D")}</div>
                                                <div><Clock size="15" color="gray" /> 10pm CST</div>
                                            </div>
                                            <div className="date">
                                                <div className="day-nbr small">3</div>
                                                <div className="day-name small">DAYS</div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {!isLoading &&
                                    <>
                                        <p className="mt-4">We apologize for any inconvenience this may cause. For urgent assistance during this time, please contact us at support@rosnet.com or call 816-746-4100. </p>
                                        <a href="/home" className="btn btn-primary mt-2 mb-2">Continue to Home Page</a>
                                    </>
                                }

                            </>

                        }
                        {maintMode === "active" &&
                            <>
                                <div className="list-item no-animation mb-4">
                                    <div className="item-container">
                                        <div className="avatar">
                                            <IconAlert color={maintMode === 'warning' ? '#FFB81C' : '#E03C31'} />
                                        </div>
                                        <div className="title-container">
                                            <div className="title"><h3>Planned Maintenance in Progress</h3></div>
                                            <div className="description">
                                            PowerCenter is currently offline due to scheduled maintenance for the following clients:
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                {isLoading && (
                                    <div className="loading-box">
                                        <BeatLoader color={"#004b87"} /><span className="ms-3">Gathering a list of affected clients...</span>
                                    </div>
                                )}

                                {!isLoading &&
                                    <>
                                        <div className={`calendar-list-item animate-flip-vertical active`}>
                                            <div className="date">
                                                <div className="day-name">{dayjs(new Date()).format("ddd").toUpperCase()}</div>
                                                <div className="day-nbr">{dayjs(new Date()).format("DD")}</div>
                                            </div>
                                            <div className="d-flex align-items-center p-1">
                                                <div className="vertical-line"></div>
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center p-3">
                                                <h5>AAG - Flynn Applebee's</h5>
                                                <div className="mb-2"><Calendar size="15" /> {dayjs(new Date()).format("dddd, MMMM D")}</div>
                                                <div><Clock size="15" /> 10pm CST</div>
                                            </div>
                                            <div className="date"><AlertCircle size="35" color="#E03C31" /></div>
                                        </div>

                                        <div className={`calendar-list-item animate-flip-vertical active`}>
                                            <div className="date">
                                                <div className="day-name">{dayjs(new Date()).format("ddd").toUpperCase()}</div>
                                                <div className="day-nbr">{dayjs(new Date()).format("DD")}</div>
                                            </div>
                                            <div className="d-flex align-items-center p-1">
                                                <div className="vertical-line"></div>
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center p-3">
                                                <h5>CACTUS - Cactus Club Cafe</h5>
                                                <div className="mb-2"><Calendar size="15" /> {dayjs(new Date()).format("dddd, MMMM D")}</div>
                                                <div><Clock size="15" color="gray" /> 10pm CST</div>
                                            </div>
                                            <div className="date"><AlertCircle size="35" color="#E03C31" /></div>
                                        </div>

                                        <h5 className="mt-4 mb-2">Upcoming</h5>

                                        <div className={`calendar-list-item animate-flip-vertical`}>
                                            <div className="date">
                                                <div className="day-name">{dayjs(new Date()).add(3, 'day').format("ddd").toUpperCase()}</div>
                                                <div className="day-nbr">{dayjs(new Date()).add(3, 'day').format("DD")}</div>
                                            </div>
                                            <div className="d-flex align-items-center p-1">
                                                <div className="vertical-line"></div>
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center p-3">
                                                <h5>DChevys - Doherty Chevys</h5>
                                                <div className="mb-2"><Calendar size="15" /> {dayjs(new Date()).add(3, 'day').format("dddd, MMMM D")}</div>
                                                <div><Clock size="15" color="gray" /> 10pm CST</div>
                                            </div>
                                            <div className="date">
                                                <div className="day-nbr small">2</div>
                                                <div className="day-name small">DAYS</div>
                                            </div>
                                        </div>
                                    </>
                                }


                                {!isLoading &&
                                    <>
                                        <p className="mt-4">We apologize for any inconvenience this may cause. For urgent assistance, please contact us at support@rosnet.com or call 816-746-4100. </p>
                                        <Button variant="primary" className="mt-2 mb-2" onClick={() => console.log("show site switcher")}>Switch Sites</Button>
                                    </>
                                }
                            </>
                        }

                    </section>

                </Col>
            </Row>

        </Container>
    );
}

export default Maintenance;
