import React, { useState, useEffect, lazy, Suspense } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    Offcanvas,
    Tooltip,
    OverlayTrigger,
    Popover,
    DropdownButton,
    Dropdown,
    Form
} from "react-bootstrap";
import { PlusCircle, Info, Edit, ArrowLeftCircle, ArrowRightCircle } from "react-feather";

import * as Icon from 'react-feather';

import apiHelper from '../../Helpers/OAuth/apiHelper';

import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
// import SideMenu from "../../Components/SideMenu/SideMenu";
import LayoutMenu from "../../Components/LayoutMenu/LayoutMenu";
import WizardModal from "../../Components/Dashboard/Wizard/WizardModal";

// import LandingKPI from "../../Components/Landing/LandingKPI";
import LandingCTA from "../../Components/Landing/LandingCTA";
// import LandingToggle from "../../Components/Landing/LandingToggle";
import Carousel from "../../Components/Carousel/Carousel";

// import sidemenuItems from "../../Components/SideMenu/SampleData/dashboard-items.json";

import { dynamicSort } from '../../Helpers/DynamicSort';
import { getChartColors } from '../../Helpers/ChartColors';

// import OffcanvasDashboardWizard from "../../Components/OffcanvasDashboardWizard/OffcanvasDashboardWizard";
import OffcanvasColorPalette from "../../Components/OffcanvasColorPalette/OffcanvasColorPalette";

import OffcanvasTasklist from "../../Components/OffcanvasTasklist/OffcanvasTasklist";
import OffcanvasNotifications from "../../Components/OffcanvasNotifications/OffcanvasNotifications";
import OffcanvasEmployeeRequests from "../../Components/OffcanvasEmployeeRequests/OffcanvasEmployeeRequests";
import OffcanvasCalendar from "../../Components/OffcanvasCalendar/OffcanvasCalendar";
import OffcanvasMenu from "../../Components/OffcanvasMenu/OffcanvasMenuNew";
import SideMenu from "../../Components/SideMenu/SideMenu";

// import DashboardMegaMenu from "./MegaMenu";
// import MaintenanceBanner from "../../Components/Maintenance/Banner";

import "./Styles.css";
import "../../Styles/Widgets.css";

import gridLayoutOptions from "../../Components/LayoutMenu/grid-layouts.json";


const arr = [
    "barv",
    "donut",
    "barh",
    "line",
    "pie",
    "table",
    "bubble",
    "barv",
    "donut"
]
const layouts = [
    {
        "id": "4,4,4,4,4,4,4,4,4"
    },
    {
        "id": "6,6,4,4,4,12,4,4,4"
    },
    {
        "id": "9,3,3,9,9,3,3,9,12"
    }
];

const fakeDashboard = {
    title: 'Turnover Trends',
    dashboardConfig: {
        widgets: [],
        layout: "4,4,4,12,6,6"
    }
}

function Dashboards() {


    let isRotated = false;
    const [showWizard, setShowWizard] = useState(false);
    const [selectedGridLayout, setSelectedGridLayout] = useState(layouts[1].id.split(','))
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFullScreenModal, setShowFullScreenModal] = useState(false);

    const [dashboardList, setDashboardList] = useState([]);
    const [selectedDashboard, setSelectedDashboard] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [showFilters, setShowFilters] = useState(false)
    const [showWidgetLibrary, setShowWidgetLibrary] = useState(false)
    const [showLayouts, setShowLayouts] = useState(false)
    const [widgets, setWidgets] = useState([])
    const [itemBeingDragged, setItemBeingDragged] = useState(null)
    const [compositeFilters, setCompositeFilters] = useState(null) // null important!
    const [dashboardData, setDashboardData] = useState(null);

    const [showColorPalette, setShowColorPalette] = useState(false);
    const [chartColors, setChartColors] = useState(null)
    const [selectedWidget, setSelectedWidget] = useState(null)

    const [showEmployeeRequests, setShowEmployeeRequests] = useState(false);
    const [showTasklist, setShowTasklist] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showFeatures, setShowFeatures] = useState(false);
    const [forceRerender, setForceRerender] = useState(new Date());
    const [dataTransferSuccess, setDataTransferSuccess] = useState(false);
    const [demoLoadingState, setDemoLoadingState] = useState(null);
    const [demoMaint, setDemoMaint] = useState('')

    const [showDashboardMegaMenu, setShowDashboardMegaMenu] = useState(false);
    const [showSideMenu, setShowSideMenu] = useState(true);
    const [showMaintModal, setShowMaintModal] = useState(false)
    const [maintMode, setMaintMode] = useState('')

    let host = "http://localhost:3100";
    // when running at Heroku
    if (document.location.href.indexOf('localhost') === -1) {
        host = ""; // port 3000 works in build version
    }
    // console.log("host", host)

    useEffect(() => {
        document.title = 'Dashboards | PowerCenter';
        const colors = getChartColors()
        // console.log("getChartColors", colors)
        setChartColors(colors)
        getData()


        if (window.innerWidth < 768) {
            setShowSideMenu(false)
            handleSideMenuToggle()
        }


        // Get a specific parameter value
        const urlParams = new URLSearchParams(window.location.search);
        let maintValue = urlParams.get('maint');
        setMaintMode(maintValue)

        // console.log("maint", maintValue)

        if (maintValue && maintValue.toLowerCase() === 'a') {
            setShowNotifications(true)
            setDemoMaint(maintValue)
        }
        else if (maintValue && maintValue.toLowerCase() === 'b') {
            setShowMaintModal(true)
            setDemoMaint(maintValue)
        }
        else if (maintValue && maintValue.toLowerCase() === 'c') {
            window.location.href = "/maintenance?mode=warning"
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 1000)

    }, []);


    // this listens for any global state changes to selectedDashboard ONLY
    useEffect(() => {
        //console.log("global state change to selectedDashboard", selectedDashboard)

        // make sure not null (like on page reload when this isn't available yet)
        // otherwise it causes multiple calls to APIs by the components as side effect
        if (selectedDashboard) {

            let list = []
            let distinctFilters = []

            setSelectedGridLayout(selectedDashboard.layout)

            selectedDashboard.components.map(component => {

                // add each component's filters to a distinct list to tell the composite filter component 
                // what to display
                component.filters.map(filter => {
                    let found = distinctFilters.find(function (f) {
                        return f === filter
                    })
                    if (!found) {
                        distinctFilters.push(filter)
                    }
                })

                // console.log("component", component)

                //const LazyComponent = React.lazy(() => import("../../Components/Dashboard/" + component.component))
                list.push({
                    // creating this as an "attributes" bundle to pass things more easily into the component without including the instance
                    item: { ...component },
                    colors: chartColors,
                    grid_id: list.length + 1,
                    grid_order: list.length + 1,
                    //dataPromise: ServiceHelper.Request(component),
                    instance: React.lazy(() => import("../../Components/Dashboard/" + component.component))


                })
                //console.log("item", { ...component })
            })


            setWidgets(list);

            //console.log("distinctFilters", distinctFilters)
            //setFiltersNeededForSelectedDashboard(distinctFilters)

        }

    }, [selectedDashboard])

    function demoIsLoading() {
        setDemoLoadingState(new Date());
    }

    async function getData() {

        setIsLoading(true)

        const headers = {};
        headers["Content-Type"] = "application/json";
        //headers.Authorization = "Bearer " + userData.access_token;

        const options = {};
        options.method = "GET";
        options.headers = headers;
        //options.body = JSON.stringify(request); // POST

        let url = "http://localhost:3100/api/dashboards";
        // when running at Heroku
        if (document.location.href.indexOf('localhost') === -1) {
            url = "/api/dashboards";
        }

        try {
            // uses axios interceptor to handle 401s by attempting to refresh the token and retry the request
            const response = await apiHelper.get(url, { page: 1 });

            // if there are dashboards for the user, default the first one to selected
            if (response.data && response.data.length > 0) {

                setDashboardData(response.data);

                reformatDashboardDataForSideMenu(response.data)

                setSelectedDashboard(response.data[0])

                // use spread operator to force UI to update
                setSelectedGridLayout({ ...response.data[0].layout, updated: true })
            }

        }
        catch {
            console.log("error fetching data")
        }


        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    }

    function reformatDashboardDataForSideMenu(data) {
        let list = []
        // these just demonstrate the different parents for SideMenu
        let rd = { id: 1, label: "Rosnet Dashboards", subs: [] }
        let cd = { id: 2, label: "Company Dashboards", subs: [] }
        let md = { id: 3, label: "My Dashboards", subs: [] }
        data.forEach(function (item, index) {

            rd.subs.push({ id: item.dashboard_id, label: item.title })
            cd.subs.push({ id: item.dashboard_id, label: item.title })
            md.subs.push({ id: item.dashboard_id, label: item.title })

        })
        list.push(rd)
        list.push(cd)
        list.push(md)

        // console.log("list", list)
        setDashboardList(list)

    }




    function onSelectDashboard(dash) {

        //console.log("selected dashboard", dash)

        let db = dashboardData.find(d => d.dashboard_id === dash.dashboard_id);
        setSelectedDashboard({ ...db });

        setSelectedGridLayout({ ...dash.layout, updated: true })

    }

    // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element
    function onDragOver(e) {
        //event.stopPropagation();
        e.preventDefault();
        //console.log("onDragOver", e.target.id)
    }

    function onDragEnter(e, widget) {
        e.stopPropagation();
        e.preventDefault();
        console.log("onDragEnter", e.target.id)
        let container_ndx = e.target.dataset.container
        //console.log("onDrageEnter", "source", itemBeingDragged, "target", widget)
    }

    function onDragStart(e, widget) {
        //console.log("onDragStart", e.target.id, "widget", widget)
        e.dataTransfer.setData("text", e.target.id);

        setItemBeingDragged(widget)
    }

    function onDrop(e, widget) {
        e.preventDefault();
        e.stopPropagation();
        //var data = e.dataTransfer.getData("text");
        //e.target.appendChild(document.getElementById(data));
        //let container_ndx = e.target.dataset.container
        //console.log("onDrop container_ndx", container_ndx)

        //console.log("onDrop", "source", itemBeingDragged, "target", widget)

        let sourceOrder = itemBeingDragged.grid_order;
        let targetOrder = widget.grid_order;

        let items = [...widgets]  // shallow copy

        //console.log("items", items)

        items.forEach(function (item) {

            // this swaps places between the source and target
            if (item.grid_id === itemBeingDragged.grid_id) {
                item.grid_order = targetOrder
            }
            else if (item.grid_id === widget.grid_id) {
                item.grid_order = sourceOrder
            }

        })

        //console.log("new items", JSON.stringify(items, null, 2))

        items.sort(dynamicSort('grid_order', 1))

        setWidgets([...items])

        // API call to persist the order change...
        let dashboardToUpdate = { ...selectedDashboard }
        dashboardToUpdate.components = items.map(e => e.item) // map reduce to just the raw item
        dashboardToUpdate.components.forEach(function (c) {
            // this swaps places between the source and target
            if (c.component_id === itemBeingDragged.item.component_id) {
                c.order = targetOrder
            }
            else if (c.component_id === widget.item.component_id) {
                c.order = sourceOrder
            }
        })
        dashboardToUpdate.components.sort(dynamicSort('order', 1))
        saveDashboardChange(dashboardToUpdate)

        // ********************************************************
        // trying to save a round trip back to the API/DB 
        // we also have to update the dashboardList locally...
        let copyDashboardList = [...dashboardList]
        copyDashboardList.forEach(function (item) {
            if (item.dashboard_id === dashboardToUpdate.dashboard_id) {
                item.components = dashboardToUpdate.components
                item.components.sort(dynamicSort('order', 1))
                //console.log("updating d...")
            }
        })
        setDashboardList([...copyDashboardList])
        // ********************************************************


    }


    function onRemoveWidget(widget) {

        //console.log("onRemoveWidget", widget)

        let dashboardToUpdate = { ...selectedDashboard }


        let filtered = dashboardToUpdate.components.filter(c => c.component_id !== widget.component_id)

        // update the state of the selectedDashboard
        dashboardToUpdate.components = filtered
        setSelectedDashboard(dashboardToUpdate)

        // API call to persist the change...
        saveDashboardChange(dashboardToUpdate)

        // ********************************************************
        // trying to save a round trip back to the API/DB 
        // we also have to update the dashboardList locally...
        let copyDashboardList = [...dashboardList]
        let found = copyDashboardList.find(function (f) {
            return f.dashboard_id === dashboardToUpdate.dashboard_id
        })
        if (found) {
            found.components = dashboardToUpdate.components
            //console.log("found and updating layout")
        }
        setDashboardList([...copyDashboardList])
        // ********************************************************

    }

    async function saveDashboardChange(dashboard) {

        //console.log("saveDashboardChange", dashboard)

        const options = {
            "method": "PUT",
            //"headers": {"Content-Type":"application/json"},
            "body": JSON.stringify(dashboard)
        };

        const response = await fetch('http://localhost:3000/api/dashboards/' + dashboard.dashboard_id, options)
        //const data = await response.json()


    }

    function onLayoutSelect(layout) {
        // use spread operator to force UI to update
        setSelectedGridLayout({ ...layout, updated: true })
    }

    function onColorPaletteChange(palette) {
        // console.log("on select palette", palette)
    }

    function onTriggerDisplayRefresh(palette) {
        // console.log("on select palette", palette)
        let newColors = getChartColors()
        // console.log("new chart colors", newColors)
        setChartColors(newColors)

        // getData()
        setSelectedDashboard({ ...selectedDashboard })
    }

    function onCloseColorPalette() {

        setShowColorPalette(false);

        // let newColors = getChartColors()
        // // console.log("new chart colors", newColors)
        // setChartColors(newColors)

        // // getData()
        // setSelectedDashboard({ ...selectedDashboard })

    }

    function onSelectChartType(c, type) {

        let newCopy = JSON.parse(JSON.stringify(selectedDashboard))
        let found = newCopy.components.find(e => e.component_id === c.item.component_id)
        if (found) {
            found.component = type
        }

        setSelectedDashboard({ ...newCopy })
    }

    function onShowFullScreen(Component) {

        setShowFullScreenModal(true)
        setSelectedWidget(Component)

        console.log("component", Component)
    }

    function onCategorySelection(category) {
        //console.log("category", category);
    }
    function onSubSelection(sub) {

        let db = dashboardData.find(d => d.dashboard_id === sub.id);
        setSelectedDashboard({ ...db });

        // only affects mobile users
        setShowMenu(false);


        if (window.innerWidth < 768) {
            setShowSideMenu(false)
            handleSideMenuToggle()
        }
    }

    function displayWidgets(count) {

        return (

            // TODO: row with display:flex is causing the sidemenu issue
            <Row className="g-2">

                {widgets.map((Component, index) => {
                    return (
                        <div
                            key={index}
                            className={"widget-grid-wrapper col-12 col-sm-12 col-md-6 col-lg-" + selectedGridLayout[index]}
                            onDrop={(e) => onDrop(e, Component)}
                            onDragOver={onDragOver}
                            onDragEnter={(e) => onDragEnter(e, Component)}
                        >

                            <div className="widget--menu">
                                {/* <button className="btn" style={{ marginTop: 5, marginLeft: -10, borderColor: "transparent", marginLeft: 0, padding: "0.375rem 0.75rem 0.375rem 0" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <Icon.Settings style={{ height: 15, color: "gray" }} />
                                </button> */}

                                <DropdownButton title={<Icon.Settings style={{ height: 15, color: "gray" }} />} variant="none">
                                    <Dropdown.Item onClick={() => console.log("add filter")} disabled><Icon.Filter style={{ height: 15, color: "silver" }} /> Add Filter</Dropdown.Item>
                                    <Dropdown.Item onClick={() => console.log("remove")} disabled><Icon.X style={{ height: 15, color: "silver" }} />Remove Widget</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onShowFullScreen(Component)}><Icon.Maximize2 style={{ height: 15, color: "gray" }} />View Full Screen</Dropdown.Item>
                                    {Component.item.component !== "Table" && Component.item.component !== "KPI" &&
                                        <DropdownButton className="show-toggle dropend mx-1" title={<><Icon.PieChart style={{ height: 15, color: "gray" }} />Chart Type</>} variant="none">
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "BarChartVertical")}>Vertical Bar Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "BarChartHorizontal")}>Horizontal Bar Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "LineChart")}>Line Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "PieChart")}>Pie Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "DoughnutChart")}>Donut Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "Table")} disabled>Table View</Dropdown.Item>
                                        </DropdownButton>
                                    }
                                </DropdownButton>

                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#"
                                        onClick={() => onRemoveWidget(Component.item)}>
                                        <Icon.XCircle size={20} color={'gray'} strokeWidth={1} />Remove
                                    </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <Icon.Filter size={20} color={'gray'} strokeWidth={1} />Filter
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div
                                className="widget-grid-item d-flex flex-column"
                                style={{ height: 325 }}
                                draggable="true"
                                onDragStart={(e) => onDragStart(e, Component)}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Component.instance
                                        dataPromise={Component.dataPromise}
                                        compositeFilters={compositeFilters}
                                        host={host}
                                        item={Component.item}
                                        chartColors={Component.colors}
                                        userData={{ access_token: 'faked...' }}
                                        widgetHeight={270}
                                    />
                                </Suspense>
                            </div>
                        </div>

                    )
                })}
            </Row>
        )
    }

    function renderDashbardMenuOptions() {

        return (
            <>
                <button className="btn btn-outline-default" onClick={() => getData()}>
                    <Icon.RefreshCw style={{ height: 15 }} />Refresh
                </button>
                <button className="btn btn-outline-default" onClick={() => setShowColorPalette(!showColorPalette)}>
                    <Icon.Sliders style={{ height: 15 }} />Colors
                </button>
                <button className="btn btn-outline-default" onClick={() => setShowLayouts(!showLayouts)}>
                    <Icon.Layout style={{ height: 15 }} />Layout
                </button>
                <button className="btn btn-outline-default text-black-50" data-bs-toggle="modal" onClick={() => setShowFilters(!showFilters)} data-bs-target={'#compositeFiltersModal'}>
                    <Icon.Filter style={{ height: 15 }} />Filter
                </button>
                <button className="btn btn-outline-default text-black-50" data-bs-toggle="modal" onClick={() => setShowWidgetLibrary(!showWidgetLibrary)} data-bs-target={'#widgetLibraryModal'}>
                    <Icon.Grid style={{ height: 15 }} />Widgets
                </button>
                <button className="btn btn-outline-default text-black-50">
                    <Icon.UserCheck style={{ height: 15 }} />Assign
                </button>
                <button className="btn btn-outline-default text-black-50">
                    <Icon.Share style={{ height: 15 }} />Share
                </button>
                <button className="btn btn-outline-default text-black-50">
                    <Icon.Copy style={{ height: 15 }} />Copy
                </button>
                <button className="btn btn-outline-default text-black-50">
                    <Icon.Edit style={{ height: 15 }} />Edit
                </button>
            </>
        )
    }

    function handleSideMenuToggle() {

        // animation for button and image
        const button = document.getElementById('menu-toggle-button');
        const image = document.getElementById('rotatable-image');
        button.classList.add('scale-25');


        setTimeout(() => {
            button.classList.remove('scale-25');
        }, 200);

        if (image.classList.contains('rotated-left')) {
            image.classList.remove('rotated-left');
            image.classList.add('rotated-right');
            setShowSideMenu(true)
        } else {
            image.classList.remove('rotated-right');
            image.classList.add('rotated-left');
            setShowSideMenu(false)
        }

    }

    return (
        <Container fluid>
            <TopNav dataTransferSuccess={true} />

            {/* <MaintenanceBanner message="Planned System Maintenance on 10/17 10pm-11pm CST" /> */}

            <div className="d-flex mt-0 mb-2">
                {/* <LandingToggle onChangeToggle={demoIsLoading} /> */}

                <Carousel
                    increment={100}
                    showEmployeeRequests={true}
                    dataTransferSuccess={dataTransferSuccess}
                    items={
                        <>

                            <LandingCTA
                                title="Remaining Tasks"
                                href="#"
                                image="./img/cta/cta-tasklists-primary.svg"
                                onClick={() => {
                                    setShowTasklist(true);
                                    setForceRerender(new Date());
                                }}
                                count={3}
                                showCount={true}
                            />


                            <LandingCTA
                                title="Employee Requests"
                                href="#"
                                image="./img/cta/cta-employee-requests-primary.svg"
                                onClick={() => {
                                    setShowEmployeeRequests(true)
                                    setForceRerender(new Date());
                                }}
                                count={12}
                                showCount={true}
                            />


                            <LandingCTA
                                title="Calendar Events"
                                href="#"
                                image="./img/cta/cta-calendar-primary.svg"
                                onClick={() => {
                                    setShowCalendar(true)
                                    setForceRerender(new Date());
                                }}
                                count={7}
                                showCount={true}
                            />

                            <LandingCTA
                                title="Menu Favorites"
                                href="#"
                                image="./img/cta/cta-favorites-primary.svg"
                                onClick={() => {
                                    setShowMenu(true)
                                    setForceRerender(new Date());
                                }}
                                count={32}
                                showCount={true}
                            />

                            <LandingCTA
                                title="Notifications"
                                href="#"
                                image={`./img/cta/cta-notifications-${maintMode === 'a' ? 'warning' : 'primary'}.svg`}
                                onClick={() => {
                                    setShowNotifications(true)
                                    setForceRerender(new Date());
                                }}
                                count={9}
                                showCount={true}
                            />

                        </>
                    }
                />
            </div>


            <div className="d-flex mt-2 pt-2" style={{ height: '100%', width: '100%' }}>
                {/* SideMenu div container with min-width */}
                <div
                    className={`side-menu-wrapper ${showSideMenu ? 'show' : 'hide'}`}
                    style={{
                        minWidth: showSideMenu ? '260px' : '0',
                    }}
                >
                    {showSideMenu && (
                        <SideMenu
                            // the category to select by default (0 based index)
                            defaultCategoryIndex={2}
                            // array of items in the format that the component expects
                            items={dashboardList}
                            // when a user selects a category
                            onCategorySelection={(cat) => onCategorySelection(cat)}
                            // default the selected subcategory item
                            defaultSub={{ id: 1 }}
                            // when a user selects a subcategory
                            onSubSelection={(sub) => onSubSelection(sub)}
                            headingContent={
                                <div className="dashboard-heading">
                                    <h4>Dashboards</h4>
                                    <Button
                                        variant="link"
                                        type="button"
                                        style={{ margin: 0, padding: 0 }}
                                        onClick={() => setShowWizard(true)}
                                    >
                                        <PlusCircle size={24} color={"white"} />
                                    </Button>
                                </div>
                            }
                        />
                    )}
                </div>

                {/* Dashboard-container that fills remaining horizontal space */}
                <div className="dashboard-container">
                    {isLoading &&

                        <div className="placeholders d-flex flex-grow-1 flex-column gap-2" style={{ marginLeft: -10 }}>
                            <div className="placeholder-block"></div>
                            <div className="kpi-container">
                                <div className="kpi-placeholder">
                                    <div className="kpi-header">
                                        <div className="title-placeholder"></div>
                                        <div className="icon-placeholder">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>
                                    <div className="vertical-bar-chart-container">
                                        <div className="vertical-bar-chart">
                                            <div className="bar" style={{ height: 50 }}></div>
                                            <div className="bar" style={{ height: 80 }}></div>
                                            <div className="bar" style={{ height: 120 }}></div>
                                            <div className="bar" style={{ height: 60 }}></div>
                                            <div className="bar" style={{ height: 100 }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpi-placeholder">
                                    <div className="kpi-header">
                                        <div className="title-placeholder"></div>
                                        <div className="icon-placeholder">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>
                                    <div className="vertical-bar-chart-container">
                                        <div className="vertical-bar-chart">
                                            <div className="bar" style={{ height: 50 }}></div>
                                            <div className="bar" style={{ height: 80 }}></div>
                                            <div className="bar" style={{ height: 120 }}></div>
                                            <div className="bar" style={{ height: 60 }}></div>
                                            <div className="bar" style={{ height: 100 }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpi-placeholder">
                                    <div className="kpi-header">
                                        <div className="title-placeholder"></div>
                                        <div className="icon-placeholder">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>
                                    <div className="vertical-bar-chart-container">
                                        <div className="vertical-bar-chart">
                                            <div className="bar" style={{ height: 50 }}></div>
                                            <div className="bar" style={{ height: 80 }}></div>
                                            <div className="bar" style={{ height: 120 }}></div>
                                            <div className="bar" style={{ height: 60 }}></div>
                                            <div className="bar" style={{ height: 100 }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpi-placeholder">
                                    <div className="kpi-header">
                                        <div className="title-placeholder"></div>
                                        <div className="icon-placeholder">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>
                                    <div className="vertical-bar-chart-container">
                                        <div className="vertical-bar-chart">
                                            <div className="bar" style={{ height: 50 }}></div>
                                            <div className="bar" style={{ height: 80 }}></div>
                                            <div className="bar" style={{ height: 120 }}></div>
                                            <div className="bar" style={{ height: 60 }}></div>
                                            <div className="bar" style={{ height: 100 }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpi-placeholder">
                                    <div className="kpi-header">
                                        <div className="title-placeholder"></div>
                                        <div className="icon-placeholder">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>
                                    <div className="vertical-bar-chart-container">
                                        <div className="vertical-bar-chart">
                                            <div className="bar" style={{ height: 50 }}></div>
                                            <div className="bar" style={{ height: 80 }}></div>
                                            <div className="bar" style={{ height: 120 }}></div>
                                            <div className="bar" style={{ height: 60 }}></div>
                                            <div className="bar" style={{ height: 100 }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpi-placeholder">
                                    <div className="kpi-header">
                                        <div className="title-placeholder"></div>
                                        <div className="icon-placeholder">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>
                                    <div className="vertical-bar-chart-container">
                                        <div className="vertical-bar-chart">
                                            <div className="bar" style={{ height: 50 }}></div>
                                            <div className="bar" style={{ height: 80 }}></div>
                                            <div className="bar" style={{ height: 120 }}></div>
                                            <div className="bar" style={{ height: 60 }}></div>
                                            <div className="bar" style={{ height: 100 }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {!isLoading &&
                        <Row className="h-100">
                            <Col xs={12} className="dashboard-wrapper p-0">
                                <section className="dashboard-options">
                                    <div className="title-desc">
                                        <div className="title">
                                            <Button
                                                id="menu-toggle-button"
                                                variant="link"
                                                className="btn-link-off"
                                                onClick={() => handleSideMenuToggle()}
                                            >
                                                <img src="/img/dashboard-menu-toggle.svg" alt="Collapse Menu" id="rotatable-image" />
                                            </Button>

                                            <h5 style={{ padding: 5 }}>
                                                <span className="me-0" onClick={() => setShowDashboardMegaMenu(true)}>{selectedDashboard?.title}</span>
                                                <span className="me-2" onClick={() => setShowDashboardMegaMenu(true)}></span>
                                                <OverlayTrigger
                                                    rootClose
                                                    placement={'bottom'}
                                                    trigger={'click'}
                                                    overlay={
                                                        <Popover id="popover-basic">
                                                            <Popover.Header as="h3">
                                                                <div className="d-flex flex-row justify-content-between">
                                                                    <div className="flex-grow-2">
                                                                        {selectedDashboard?.title} Dashboard
                                                                    </div>
                                                                    <div><Edit size={16} color={'gray'} /></div>
                                                                </div>
                                                            </Popover.Header>
                                                            <Popover.Body>
                                                                This is where the description is displayed.
                                                                It can support up to 350 characters of text to allow the user to share instructions or comments on why this dashboard will be helpful.
                                                                <br />
                                                                <div className="mt-3">Shared by John Smith on Mar 15, 2023</div>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <Info color={'gray'} size={20} />
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>

                                    {/* Horizontal icons */}
                                    <div className="icons mb-2 d-none d-xl-block">
                                        {renderDashbardMenuOptions()}
                                    </div>

                                    {/* Options dropdown for smaller devices */}
                                    <DropdownButton className="d-xl-none d-lg-flex" variant="elipsis" title={'Options'}>
                                        {renderDashbardMenuOptions()}
                                    </DropdownButton>
                                </section>

                                {displayWidgets(9)}
                            </Col>
                        </Row>
                    }
                </div>
            </div>





            {/* <OffcanvasDashboardWizard show={showWizard} onClose={() => setShowWizard(false)} /> */}
            <WizardModal show={showWizard} onHide={() => setShowWizard(false)} />


            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>About Dashboards</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This dashboard and dashboard wizard prototype are a work in progress.
                    I'm exploring a new left side panel that matches the design we're using in the Menu as well as some other UI improvments.

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        Got it
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                show={showFullScreenModal}
                // fullscreen={true}
                dialogClassName="custom-90w"
                onHide={() => setShowFullScreenModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Full Screen View</Modal.Title>
                </Modal.Header>
                {/* Overflow hidden allows the widget to control the overflow */}
                <Modal.Body style={{ overflow: 'hidden' }}>

                    {selectedWidget && <selectedWidget.instance
                        dataPromise={selectedWidget.dataPromise}
                        compositeFilters={compositeFilters}
                        host={host}
                        item={selectedWidget.item}
                        chartColors={selectedWidget.colors}
                        userData={{ access_token: 'faked...' }}
                        fullscreen={true}
                    />}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowFullScreenModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                show={showMaintModal}
                fullscreen={false}
                backdrop="static"
                size="md"
                onHide={() => setShowMaintModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><img src="./img/icon-notif-warning.svg" alt="Help" className="pe-2" />Planned System Maintenance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="ps-2 pe-2">PowerCenter will be offline for scheduled maintenance, starting on 10/17 between the hours of 10pm and 11pm CST.</p>
                    <p className="ps-2 pe-2">We apologize for any inconvenience this may cause. For urgent assistance during this time, please contact us at support@rosnet.com or call 816-746-4100. </p>
                </Modal.Body>
                <Modal.Footer>

                    {/* <Button variant="outline-secondary" onClick={() => setShowMaintModal(false)}>
                        Got it
                    </Button> */}

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" for="flexCheckChecked">
                            Don't remind me
                        </label>
                    </div>

                    <Button variant="primary" onClick={() => setShowMaintModal(false)}>
                        OK
                    </Button>

                </Modal.Footer>
            </Modal>


            <Offcanvas
                placement="end"
                show={showLayouts}
                onEntered={() => console.log('opening layout selector offcanvas')}
                onExited={() => setShowLayouts(false)}
                onHide={() => setShowLayouts(false)}
            >
                <Offcanvas.Header style={{ backgroundColor: '#004b87' }}>
                    <Offcanvas.Title style={{ color: 'white' }}>Choose a Layout</Offcanvas.Title>
                    {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
                    <button
                        type="button"
                        class="btn-close btn-close-white"
                        aria-label="Close"
                        onClick={() => setShowLayouts(false)}
                    ></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col>
                            <p>Choose a layout that has been designed for the {selectedDashboard && selectedDashboard?.components?.length} widgets you have selected.</p>
                            <LayoutMenu count={widgets ? widgets.length : 0} visible={true} onLayoutSelect={onLayoutSelect} selectedDashboard={selectedDashboard} />
                        </Col>
                    </Row>

                    <div className="mt-5">
                        <h5>Would you like to rearrange your widgets?</h5>
                        <p className="mt-3">When viewing your dashboard, you can rearrange the order of you widgets by using drag-and-drop from one widget to another. This gives you full control over the layout and placement of the widgets on your dashboard.</p>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>

            {showColorPalette && <OffcanvasColorPalette show={showColorPalette} onClose={onCloseColorPalette} onColorPaletteChange={onColorPaletteChange} onTriggerDisplayRefresh={onTriggerDisplayRefresh} />}



            <OffcanvasTasklist
                show={showTasklist}
                forceRerender={forceRerender}
                onClose={() => setShowTasklist(false)}
            />
            <OffcanvasNotifications
                show={showNotifications}
                forceRerender={forceRerender}
                onClose={() => setShowNotifications(false)}
            />
            <OffcanvasCalendar
                show={showCalendar}
                forceRerender={forceRerender}
                onClose={() => setShowCalendar(false)}
            />
            <OffcanvasEmployeeRequests
                show={showEmployeeRequests}
                forceRerender={forceRerender}
                onClose={() => setShowEmployeeRequests(false)}
            />
            {/* wrapping this in the same boolean var prevents React from an endless render loop */}
            {showMenu &&
                <OffcanvasMenu
                    show={showMenu}
                    searchTerm={''}
                    selectedMenuItem={null}
                    defaultCategoryIndex={1}
                    onClose={() => setShowMenu(false)}
                />
            }


        </Container>

    );
}

export default Dashboards;