import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    Tabs,
    Tab

} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BarChart } from "react-feather";

import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";

import "./Styles.css";

function DashboardConfigMenu() {

    const navigate = useNavigate();

    return (
        <Container fluid className="assign-share min-vh-100  allow-vertical-scroll">

            <TopNav dataTransferSuccess={true} />

            <Row className="justify-content-md-center mb-5 pt-3">

                <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                    <section className="mb-3">
                        <h4>Configure Dashboards</h4>
                        <p className="mb-0">Use these features to configure your dashboard settings.</p>
                    </section>

                    <section className={'hover-effect-with-pointer'} onClick={() => navigate('/admin/assign-share-list')}>
                        <div className="d-flex flex-row align-text-top gap-4">
                            <div className="avatar">
                                <div className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: 50, height: 50, backgroundColor: '#004B87' }}>
                                    <BarChart color={'white'} />
                                </div>
                            </div>
                            <div className="flex-grow-1 title-container">
                                <h5 className="no-select">Permissions to Assign/Share Dashboards</h5>
                                <div className="description mb-0 no-select">
                                    Control group-level permissions for assigning and sharing dashboards.
                                </div>

                            </div>
                        </div>
                    </section>

                </Col>

            </Row>

        </Container>
    );
}

export default DashboardConfigMenu;