import React, { useState, useEffect } from 'react';
import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import cloneDeep from "lodash/cloneDeep";

import { Users, User } from "react-feather"; // Import Chevron icons

import './Styles.css';

const nameList = "Ashley,Bryant,Bryon,Corey,Dywayne,Irina,Jason,Joel,Karl,Linda,Mark,Matt,Megan,Seth,Susan,Troy,Vasu"

function UpNext() {
    const [availableNames, setAvailableNames] = useState([]);
    const [selectedName, setSelectedName] = useState('');
    const [skippedNames, setSkippedNames] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isNewSelection, setIsNewSelection] = useState(false); // State to trigger size animation

    const radius = 210; // Adjusted outer circle radius
    const smallCircleSize = 80; // Outer circles' size
    const delay = 50; // Delay for animation in ms
    const containerSize = 500; // Container size

    function setFavicon(faviconURL) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = faviconURL;
        document.head.appendChild(link);
    }

    useEffect(() => {

        document.title = 'UpNext';
        // setFavicon('./upnext.png');

        // Get a specific parameter value
        const urlParams = new URLSearchParams(window.location.search);
        const names = urlParams.get('names');
        const randomsort = urlParams.get('randomsort') || 'true'

        if (names) {
            const namesArray = names.split(',').map(name => name.trim()).sort(() => Math.random() - 0.5);
            let shuffledNamesList = []
            // in case a tester wants to get tricky, default to random sorting unless 'false' is exactly specified
            if (!randomsort || randomsort && randomsort === 'true') {
                //shuffledNamesList = namesArray.sort(() => Math.random() - 0.5);
                shuffledNamesList = fisherYatesShuffle(namesArray);
            }
            else {
                // sort alphabetically
                shuffledNamesList = namesArray.sort();
            }
            setAvailableNames(shuffledNamesList)
        }
        else {
            // const shuffledNamesList = nameList.split(',').map(name => name.trim()).sort(() => Math.random() - 0.5);
            let shuffledNamesList = nameList.split(',').map(name => name.trim())
            shuffledNamesList = fisherYatesShuffle(shuffledNamesList);

            setAvailableNames(shuffledNamesList)
        }


    }, [])

    useEffect(() => {
        if (availableNames.length > 0) {
            spinWheel(true);
        }
    }, [availableNames]);

    useEffect(() => {
        if (selectedName) {
            setIsNewSelection(true);
            // Reset size after animation
            setTimeout(() => {
                setIsNewSelection(false);
            }, 300); // Duration should match the CSS transition
        }
    }, [selectedName]);

    // the Fisher-Yates (aka Knuth) Shuffle algorithm vs Math.random
    // For a more reliable random shuffle, the Fisher-Yates (aka Knuth) Shuffle algorithm is recommended. 
    // It ensures every possible permutation of the array has an equal chance of being produced.
    function fisherYatesShuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const spinWheel = () => {
        if (availableNames.length === 0) return;

        // If all names have been skipped, restart instead of spinning
        if (availableNames.length === skippedNames.length) {
            onRestart();
            return;
        }

        setSpinning(true);
        const totalNames = availableNames.length;
        const fullRounds = totalNames; // Ensure one full round
        const extraSteps = Math.floor(Math.random() * totalNames); // Random extra steps
        const totalSteps = fullRounds + extraSteps;

        let step = 0;
        let nextIndex = currentIndex;

        const interval = setInterval(() => {
            // Find the next index that hasn't been skipped (for non-demo mode)
            do {
                nextIndex = (nextIndex + 1) % totalNames;
            } while (skippedNames.includes(availableNames[nextIndex]));

            setCurrentIndex(nextIndex);
            step++;

            // Final step to stop spinning
            if (step === totalSteps) {
                clearInterval(interval);
                setSpinning(false);


                const selected = availableNames[nextIndex];
                setSelectedName(selected);

                // Add selected name to the skipped names list
                setSkippedNames((prev) => [...prev, selected]);

            }
        }, delay);


    };


    function onRestart() {
        setCurrentIndex(0)
        setIsNewSelection(false)
        setSpinning(false)
        setSelectedName('')
        setSkippedNames([])
    }

    function onSelectName(name, ndx) {

        const copyList = cloneDeep(skippedNames)

        const exists = copyList.find((s) => s === name);
        if (exists) {
            const filtered = copyList.filter(s => s !== name);
            setSkippedNames(filtered)
            setSelectedName('')
        }
        else {
            setSelectedName(name)
            setSkippedNames((prev) => [...prev, name]);
        }
    }

    const getCircleStyle = (name, index) => {
        const angle = (index / availableNames.length) * 2 * Math.PI; // Calculate angle for the position
        const x = containerSize / 2 + radius * Math.cos(angle) - smallCircleSize / 2; // X position
        const y = containerSize / 2 + radius * Math.sin(angle) - smallCircleSize / 2; // Y position

        const backgroundColor = spinning && index === currentIndex
            ? '#4CBB17'
            : skippedNames.includes(name)
                ? '#fff'
                : '#004B87'
        const color = spinning && index === currentIndex
            ? '#fff'
            : skippedNames.includes(name)
                ? '#333'
                : '#fff'

        return {
            left: `${x}px`,
            top: `${y}px`,
            color,
            backgroundColor
        };
    };

    const getIconClass = (name, index) => {

 
        const className = spinning && index === currentIndex
            ? 'icon-container'
            : skippedNames.includes(name)
                ? 'icon-container selected'
                : 'icon-container'

        return className
    };

    function getInnerCircleLabel() {

        if (selectedName === '' && skippedNames.length > 0) {
            return 'Resume'
        }

        if (selectedName === '' && availableNames.length === skippedNames.length) {
            return <>Start Over</>
        }

        if (spinning) {
            return <>{spinning ? 'Searching...' : selectedName ? selectedName : 'Start'}</>
        }
        else if (selectedName) {
            return (<>{selectedName}<div className="next-link">Click to Continue</div></>)
        }
        else {
            return 'Start'
        }

    }

    return (

        <div className="min-vh-100">

            <TopNav dataTransferSuccess={true} hideClientSwitcher={true} clientName="UpNext" subtitle="Random turns, organized updates." />

            {/* Main content */}
            <div className="content-wrapper text-center mt-3">
                {/* <p>With UpNext, teams can share updates at random or select members manually, making it easy to keep track of who has provided an update.</p> */}
                <div className="mt-5" style={{ position: 'relative', width: `${containerSize}px`, height: `${containerSize}px`, margin: '0 auto' }}>
                    {/* Inner circle for selected name */}
                    <div className={`d-flex flex-column inner-circle ${isNewSelection ? 'expand' : ''}`} onClick={spinWheel}>
                        {getInnerCircleLabel()}
                    </div>

                    {/* Outer circles for each available name */}
                    {availableNames.map((name, index) => (
                        <div
                            key={name}
                            className={`outer-circle ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => onSelectName(name, index)}
                            style={getCircleStyle(name, index)}
                        >

                            {/* {name} */}

                            <div className={getIconClass(name, index)}>
                                <User size={20} color={'white'} />
                                <span className="name">{name}</span>
                            </div>

                        </div>
                    ))}
                </div>

                <div className="mt-5">
                    <a href="#" className="btn-link-off" onClick={() => onRestart()}>Restart</a>
                </div>


            </div>
        </div>

    );
}

export default UpNext;