import React, { useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import cloneDeep from "lodash/cloneDeep";
import { Settings, Edit3, Minus, Code } from "react-feather";

function FieldCard({ field, onSelectionChanged, onMoreClicked, onDeleteClicked, onEditCustomClicked }) {

  const inputRef = useRef();

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onKeyDown = (event) => {
    console.log("event key", event.key);
    if (event.key === 'Enter') {
      event.preventDefault();
      setIsEditing(false)
    }
  };

  const onClickEditIcon = () => {

    setIsEditing(!isEditing)
    console.log("inputRef?", inputRef)
    // since the input field may not have been rendered before trying to set focus, we have to add this precaution
    try {
      setTimeout(() => {
        inputRef.current.focus()
      }, 500)
    }
    catch {

    }
  }

  const onChange = (val) => {
    let copyField = cloneDeep(field)
    copyField.customTitle = val
    onSelectionChanged(copyField);
  }

  function onHandleDelete() {
    setIsDeleting(true)
    setTimeout(() => {
      setIsDeleting(false)
      onDeleteClicked(field)
    }, 200)

  }


  return (

    <div className={`list-item ${isDeleting ? 'animate-deletion' : ''}`}>
      <div className="item-container">
        <div className="avatar">
          {field.customProps &&
            <img src="/img/icon-code-gray.svg" alt="Field" />
          }
          {!field.customProps &&
            <img src="/img/icon-tasklist-gray.svg" alt="Field" />
          }
        </div>
        <div className="title-container">
          <div className="title">

            {isEditing
              ? <input type="text" className="form-control w-50" value={field.customTitle}
                onChange={(e) => onChange(e.target.value)}
                onBlur={() => setIsEditing(false)}
                // onMouseOut={() => setIsEditing(false)}
                onKeyDown={e => onKeyDown(e)}
                ref={inputRef}></input>
              : <>{field.customTitle || field.title}</>
            }


            <Button variant="none" className="btn-link-off" onClick={() => onClickEditIcon()}><Edit3 size={20} color={'gray'} /></Button>
          </div>
          <div className="sub-title">
            {field.title}
          </div>
        </div>
        <span className="d-flex flex-row flex-grow-0 p-0 gap-0">
          {field.customProps &&
            <Button variant="none" onClick={() => onEditCustomClicked(field)}><Code size={20} color={'gray'} /></Button>
          }

          <Button variant="none" onClick={() => onMoreClicked('View Sample Data', field)}><Settings size={20} color={'gray'} /></Button>
          <Button variant="none" onClick={() => onHandleDelete()}><Minus size={20} color={'gray'} /></Button>
        </span>
      </div>
    </div>
  )
}

export default FieldCard;
