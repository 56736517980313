
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Container
} from "react-bootstrap";


import "./Styles.css";

function Loading() {

    const navigate = useNavigate();

    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const stay = searchParams.get('stay');

        if (!stay) {
            setTimeout(() => {
                navigate('/home')
            }, 1000);
        }

        document.title = 'Loading | PowerCenter';

    }, []);

    return (
        <Container fluid className="placeholders">


            <div className="placeholder-block" style={{ height: 64.7 }}></div>

            <div className="loading d-flex flex-column gap-2 p-2">

                <div className="d-flex flex-row gap-2">
                    <div className="placeholder-block" style={{ height: 85 }}></div>
                    <div className="placeholder-block" style={{ height: 85 }}></div>
                    <div className="placeholder-block" style={{ height: 85 }}></div>
                    <div className="placeholder-block" style={{ height: 85 }}></div>
                </div>

                <div className="d-flex flex-row gap-2">
                    <div className="sidemenu" style={{ width: 300 }}>
                        <div className="side-menu-placeholder">
                            <div className="menu-item-placeholder"></div>
                            <div className="submenu-placeholder">
                                <div className="submenu-item-placeholder"></div>
                                <div className="submenu-item-placeholder"></div>
                            </div>
                            <div className="menu-item-placeholder"></div>
                            <div className="submenu-placeholder">
                                <div className="submenu-item-placeholder"></div>
                                <div className="submenu-item-placeholder"></div>
                                <div className="submenu-item-placeholder"></div>
                            </div>
                            <div className="menu-item-placeholder"></div>
                            <div className="submenu-placeholder">
                                <div className="submenu-item-placeholder"></div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-grow-1 flex-column gap-2">
                        <div className="placeholder-block"></div>
                        <div className="kpi-container">
                            <div className="kpi-placeholder">
                                <div className="kpi-header">
                                    <div className="title-placeholder"></div>
                                    <div className="icon-placeholder">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                                <div className="vertical-bar-chart-container">
                                    <div className="vertical-bar-chart">
                                        <div className="bar" style={{ height: 50 }}></div>
                                        <div className="bar" style={{ height: 80 }}></div>
                                        <div className="bar" style={{ height: 120 }}></div>
                                        <div className="bar" style={{ height: 60 }}></div>
                                        <div className="bar" style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="kpi-placeholder">
                                <div className="kpi-header">
                                    <div className="title-placeholder"></div>
                                    <div className="icon-placeholder">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                                <div className="vertical-bar-chart-container">
                                    <div className="vertical-bar-chart">
                                        <div className="bar" style={{ height: 50 }}></div>
                                        <div className="bar" style={{ height: 80 }}></div>
                                        <div className="bar" style={{ height: 120 }}></div>
                                        <div className="bar" style={{ height: 60 }}></div>
                                        <div className="bar" style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="kpi-placeholder">
                                <div className="kpi-header">
                                    <div className="title-placeholder"></div>
                                    <div className="icon-placeholder">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                                <div className="vertical-bar-chart-container">
                                    <div className="vertical-bar-chart">
                                        <div className="bar" style={{ height: 50 }}></div>
                                        <div className="bar" style={{ height: 80 }}></div>
                                        <div className="bar" style={{ height: 120 }}></div>
                                        <div className="bar" style={{ height: 60 }}></div>
                                        <div className="bar" style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="kpi-placeholder">
                                <div className="kpi-header">
                                    <div className="title-placeholder"></div>
                                    <div className="icon-placeholder">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                                <div className="vertical-bar-chart-container">
                                    <div className="vertical-bar-chart">
                                        <div className="bar" style={{ height: 50 }}></div>
                                        <div className="bar" style={{ height: 80 }}></div>
                                        <div className="bar" style={{ height: 120 }}></div>
                                        <div className="bar" style={{ height: 60 }}></div>
                                        <div className="bar" style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="kpi-placeholder">
                                <div className="kpi-header">
                                    <div className="title-placeholder"></div>
                                    <div className="icon-placeholder">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                                <div className="vertical-bar-chart-container">
                                    <div className="vertical-bar-chart">
                                        <div className="bar" style={{ height: 50 }}></div>
                                        <div className="bar" style={{ height: 80 }}></div>
                                        <div className="bar" style={{ height: 120 }}></div>
                                        <div className="bar" style={{ height: 60 }}></div>
                                        <div className="bar" style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="kpi-placeholder">
                                <div className="kpi-header">
                                    <div className="title-placeholder"></div>
                                    <div className="icon-placeholder">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                                <div className="vertical-bar-chart-container">
                                    <div className="vertical-bar-chart">
                                        <div className="bar" style={{ height: 50 }}></div>
                                        <div className="bar" style={{ height: 80 }}></div>
                                        <div className="bar" style={{ height: 120 }}></div>
                                        <div className="bar" style={{ height: 60 }}></div>
                                        <div className="bar" style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </Container>
    );
}

export default Loading;
