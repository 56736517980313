import React, { useState, useEffect } from "react";

import { Container, Accordion, Button } from "react-bootstrap";

import "./SideMenu.css";

function SideMenu(props) {
  // selectedCategory isn't really needed currently, but may be useful in future iterations of this componenent
  // const [selectedCategory, setSelectedCategory] = useState(props?.selectedCategory);
  const [selectedSub, setSelectedSub] = useState(props?.defaultSub);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    setTimeout(() => {
      setIsLoading(false);
    }, 1000)

  }, []);

  // this broaccasts the change to the parent component if a handler is specified
  function onCategorySelection(cat) {
    // setSelectedCategory(cat);
    if (props.onCategorySelection) {
      props.onCategorySelection(cat);
    }
  }
  // this broaccasts the change to the parent component if a handler is specified
  function onSubSelection(sub) {
    // setting the selectedSub is used for styling only
    setSelectedSub(sub);
    if (props.onSubSelection) {
      props.onSubSelection(sub);
    }
  }
  return (
    <Container className="sidemenu sidemenu-container placeholders">

      {isLoading &&
        <div className="" style={{ width: 240 }}>
          <div className="side-menu-placeholder">
            <div className="menu-item-placeholder"></div>
            <div className="submenu-placeholder">
              <div className="submenu-item-placeholder"></div>
              <div className="submenu-item-placeholder"></div>
            </div>
            <div className="menu-item-placeholder"></div>
            <div className="submenu-placeholder">
              <div className="submenu-item-placeholder"></div>
              <div className="submenu-item-placeholder"></div>
              <div className="submenu-item-placeholder"></div>
            </div>
            <div className="menu-item-placeholder"></div>
            <div className="submenu-placeholder">
              <div className="submenu-item-placeholder"></div>
            </div>
          </div>
        </div>
      }

      {!isLoading &&
        <>
          <Container className="sidemenu-hero">{props.headingContent}</Container>
          <Accordion defaultActiveKey={props?.defaultCategoryIndex} flush>
            {props.items.map((category, cndx) => {
              return (
                <Accordion.Item
                  eventKey={cndx}
                  key={cndx}
                  className={"sidemenu-cat-heading"}
                >
                  <Accordion.Header
                    className={
                      !category?.subs?.length ? "hide-accordion-toggle" : ""
                    }
                    onClick={() => props?.onCategorySelection(category)}
                  >
                    <span className={"sidemenu-cat-name"}>{category.label}</span>
                  </Accordion.Header>
                  {category.subs?.length > 0 && (
                    <Accordion.Body className={"sidemenu-cat-items"}>
                      <ul className="list-unstyled">
                        {category.subs.map((item, sndx) => {
                          return (
                            <li key={sndx} className={"sidemenu-sub-item"}>
                              <div className="d-grid gap-2">
                                <Button
                                  variant={"default"}
                                  className={
                                    item.id === selectedSub?.id
                                      ? "sidemenu-sub-button active"
                                      : "sidemenu-sub-button"
                                  }
                                  onClick={() => onSubSelection(item)}
                                >
                                  {item.label}
                                </Button>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        </>
      }
    </Container>
  );
}

export default SideMenu;
